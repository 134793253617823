import React, { FC } from 'react';
import IconProps from './IconProps';

const SortIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 9.67406L10 2.66406L8 2.66406L8 9.67406L5 9.67406L9 13.6641L13 9.67406L10 9.67406ZM11 15.6541L14 15.6541L14 22.6641L16 22.6641L16 15.6541L19 15.6541L15 11.6641L11 15.6541Z"
        fill="#5A5766"
      />
    </svg>
  );
};

export default SortIcon;
